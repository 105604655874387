








































import Button from "@/components/Button.vue";
import ContentCard from "@/components/ContentCard.vue";
import { useConfirmEmailMutation } from "@/graphql/types";
import { defineComponent } from "@vue/composition-api";
import useProfile from "@/composables/useProfile";

export default defineComponent({
  components: { ContentCard, Button },
  props: {
    email: {
      type: String,
      required: false,
    },
    redirect: {
      type: String,
      required: false,
    },
    codeChallenge: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const { setEmail, profile } = useProfile();
    const { mutate, loading, error } = useConfirmEmailMutation({});

    async function confirm() {
      var result = await mutate({
        input: {
          userId: props.userId,
          codeChallenge: props.codeChallenge,
        },
      });
      if (props.email && result?.errors == null && profile) {
        setEmail(props.email!);
      }
    }

    confirm();

    return {
      loading,
      error,
    };
  },
});
